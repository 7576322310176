<template>
  <div class="media-container">
    <div class="thumbnail" v-if="showThumbnail" @click="playing = true">
      <img :src="thumbnail" :onerror="onImageError" />
      <i class="fa fa-play-circle"></i>
    </div>
    <iframe v-else-if="showVideo" :src="video_url" frameborder="0"></iframe>
    <img src="/img/video_fallback.jpg" v-else-if="error" />
    <img src="/img/no_video.jpg" v-else />
  </div>
</template>

<script>
export default {
  props: {
    video_url: {
      type: String,
      default: null,
    },
    thumbnail: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    playing: false,
    error: false,
  }),
  computed: {
    showThumbnail() {
      return !this.error && !this.playing && this.thumbnail;
    },
    showVideo() {
      return this.playing && this.video_url;
    },
  },
  methods: {
    onImageError(e) {
      console.log(e);
      this.error = true;
    },
  },
};
</script>
