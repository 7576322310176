<template>
  <div>
    <PageHeader fileName="header-2">
      <h3>{{ $lang("header_title") }}</h3>
    </PageHeader>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-5">
            <h2 class="mb-4" v-html="$lang('future.choose_future')" />
            <h4>{{ $lang("future.by_learning") }}</h4>
          </div>
          <div class="col-lg-6 offset-lg-1 mt-4 mt-lg-0">
            <h6 class="fw-400 mb-4 lh-3">
              {{ $lang("future.mission_paragraph") }}
            </h6>
            <p>
              {{ $lang("future.history_1") }}
            </p>
            <p>
              {{ $lang("future.history_2") }}
            </p>
            <p>
              {{ $lang("future.joining_nlcollege") }}
            </p>
          </div>

          <div class="col-12 mt-5">
            <MediaContainer
              class="media-container-rounded"
              thumbnail="/web-v2/img/video_cover_2.png"
              video_url="https://www.youtube.com/embed/2aSYjRFleUc"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-dark">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="text-primary mb-4">
              {{ $lang("methodology_section.title") }}
            </h3>
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("methodology_section.description") }}
            </h6>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <ConnectedList :items="$lang('methodology_section.items')" />
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h3 class="mb-4">
              {{ $lang("levels.title") }}
            </h3>
          </div>
          <div class="col-lg-6 offset-lg-2">
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("levels.description") }}
            </h6>
          </div>
          <div class="col-12">
            <div class="form-row mt-4">
              <div
                class="col-md-4 col-lg-2 mb-4"
                v-for="(l, i) of $lang('levels.items')"
                :key="i"
              >
                <div
                  class="card card-level no-shadow"
                  :class="`card-${l.theme}`"
                >
                  <div class="card-body">
                    <p class="fw-600">{{ l.title }}</p>
                    <ul class="disc-style small">
                      <li v-for="(c, ci) of l.courses" :key="ci">{{ c }}</li>
                    </ul>
                    <p class="mt-auto mb-0 smaller" v-html="l.body" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-6">
      <div class="container">
        <div class="row mb-6">
          <div class="col-lg-4">
            <h3 class="mb-4" v-html="$lang('progression.title')" />
          </div>
          <div class="col-lg-6 offset-lg-2">
            <h6 class="fw-300 lh-3 mb-5">
              {{ $lang("progression.subtitle") }}
            </h6>
          </div>
        </div>

        <div class="row mb-6">
          <div class="col-12 d-flex flex-column gap-4">
            <h3 class="fw-500">
              <i>{{ $lang("progression.spanish") }}</i>
            </h3>

            <h4 class="fw-500">
              <i class="fa fa-arrow-right text-primary"></i>
              {{ $lang("progression.accelerated") }}
              <span class="fw-300 smaller">
                {{ $lang("progression.accelerated_description") }}
              </span>
            </h4>

            <div class="levels-table">
              <table>
                <tr>
                  <td class="bg-secondary">A1.1</td>
                  <td class="bg-secondary">A1.2</td>
                  <td class="bg-secondary">A2.1</td>
                  <td class="bg-secondary">A2.2</td>
                  <td class="bg-secondary">A2.3</td>
                  <td class="bg-tertiary">
                    B1.1 <br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.1<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">B2.1</td>
                  <td class="bg-tertiary">B2.2</td>
                  <td class="bg-primary">C1.1</td>
                  <td class="bg-primary">C1.2</td>
                  <td class="bg-primary">C2.1</td>
                  <td class="bg-primary">C2.2</td>
                </tr>
                <tr>
                  <td class="bg-secondary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    2<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    9<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    9<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    12<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    12<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="row">
              <div class="col-lg-12 mb-4 mb-lg-0">
                <div
                  class="card card-outline-tertiary d-flex flex-column h-100"
                >
                  <div class="card-header-bildungsurlaub">
                    <b>{{ $lang("progression.bildungsurlaub_title") }}</b>
                  </div>
                  <div class="card-body-bildungsurlaub">
                    <p class="mb-0">
                      {{ $lang("progression.bildungsurlaub_description") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h4 class="fw-500">
              <i class="fa fa-arrow-right text-primary"></i>
              {{ $lang("progression.semi_intensive") }}
              <span class="fw-300 smaller">
                {{ $lang("progression.semi_intensive_description") }}
              </span>
            </h4>

            <div class="levels-table">
              <table>
                <tr>
                  <td class="bg-secondary">A1.1</td>
                  <td class="bg-secondary">A1.2</td>
                  <td class="bg-secondary">A2.1</td>
                  <td class="bg-secondary">A2.2</td>
                  <td class="bg-secondary">A2.3</td>
                  <td class="bg-tertiary">
                    B1.1<br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.1<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">B2.1</td>
                  <td class="bg-tertiary">B2.2</td>
                  <td class="bg-primary">C1.1</td>
                  <td class="bg-primary">C1.2</td>
                  <td class="bg-primary">C2.1</td>
                  <td class="bg-primary">C2.2</td>
                </tr>
                <tr>
                  <td class="bg-secondary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    4<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    18<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    18<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    20<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    20<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    24<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    24<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                </tr>
              </table>
            </div>

            <h4 class="fw-500">
              <i class="fa fa-arrow-right text-primary"></i>
              {{ $lang("progression.extensive") }}
              <span class="fw-300 smaller">
                {{ $lang("progression.extensive_description") }}
              </span>
            </h4>

            <div class="levels-table">
              <table>
                <tr>
                  <td class="bg-secondary">A1.1</td>
                  <td class="bg-secondary">A1.2</td>
                  <td class="bg-secondary">A2.1</td>
                  <td class="bg-secondary">A2.2</td>
                  <td class="bg-secondary">A2.3</td>
                  <td class="bg-tertiary">
                    B1.1<br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.1<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Beginner</small>
                  </td>
                  <td class="bg-tertiary">
                    B1.2<br />
                    <small>Advanced</small>
                  </td>
                  <td class="bg-tertiary">B2.1</td>
                  <td class="bg-tertiary">B2.2</td>
                  <td class="bg-primary">C1.1</td>
                  <td class="bg-primary">C1.2</td>
                  <td class="bg-primary">C2.1</td>
                  <td class="bg-primary">C2.2</td>
                </tr>
                <tr>
                  <td class="bg-secondary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-secondary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    10<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    45<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-tertiary">
                    45<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    50<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    50<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    60<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                  <td class="bg-primary">
                    60<br />
                    {{ $lang("progression.weeks") }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-dark text-white">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <h4 class="mb-5 mb-lg-0 text-white">
              {{ $lang("letushelp.title") }} <br />
              <span class="material-symbols-outlined arrow_right_alt">
                arrow_right_alt
              </span>
            </h4>
          </div>
          <div class="col-lg-4 mb-4 mb-lg-0">
            <CardButton
              theme="primary"
              :title="$lang('letushelp.card2.header')"
              :to="{ path: '/courses/our-courses' }"
            >
              <small class="m-0">
                {{ $lang("letushelp.card2.body") }}
              </small>
            </CardButton>
          </div>
          <div class="col-lg-4">
            <CardButton
              theme="primary"
              :title="$lang('letushelp.card3.header')"
              :to="{ name: 'extra_activities' }"
            >
              <small class="m-0">
                {{ $lang("letushelp.card3.body") }}
              </small>
            </CardButton>
          </div>
        </div>
      </div>
    </section>

    <ContactFormSection fileName="footer-2" theme="primary">
      {{ $lang("contact_form.location") }}
    </ContactFormSection>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import ContactFormSection from "@/components/ContactFormSection.vue";
import ConnectedList from "@/components/ConnectedList.vue";
import CardButton from "@/components/CardButton.vue";
import MediaContainer from "@/components/MediaContainer.vue";

export default {
  components: {
    PageHeader,
    ContactFormSection,
    MediaContainer,
    ConnectedList,
    CardButton,
  },
};
</script>

<style scoped>
.card-header-bildungsurlaub {
  color: #363636;
  padding: 1.5em 1.5em 0 1.5em;
}

.card-body-bildungsurlaub {
  padding: 1em 1.5em 1.5em 1.5em;
  p {
    font-size: 12px;
    color: #363636;
  }
}
</style>
